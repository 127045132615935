export interface TubeFeedObject {
    kcal:number;
    protein:number;
    water:number;
    name:string;
    reference:string;
    micros:Micros;
}

type Micro = {
    name:string;
    amount:number | null;
    unit:string;
}

export interface Micros {
    fat?:Micro,
    carbohydrate?:Micro,
    fiber?:Micro,
    lCarnitine?:Micro,
    taurine?:Micro,
    inositol?:Micro,
    vitA?:Micro,
    vitD?:Micro,
    vitE?:Micro,
    vitK?:Micro,
    vitC?:Micro,
    folate?:Micro,
    thiamin?:Micro,
    riboflavin?:Micro,
    vitB6?:Micro,
    vitB12?:Micro,
    niacin?:Micro,
    choline?:Micro,
    biotin?:Micro,
    pantothenicAcid?:Micro,
    sodium?:Micro,
    potassium?:Micro,
    chloride?:Micro,
    calcium?:Micro,
    phosphorus?:Micro,
    magnesium?:Micro,
    iodine?:Micro,
    manganese?:Micro,
    copper?:Micro,
    zinc?:Micro,
    iron?:Micro,
    selenium?:Micro,
    chromium?:Micro,
    molybdenum?:Micro,
    mct?:Micro,
    retinol?:Micro,
    betaCarotene?:Micro,
    folicAcid?:Micro,
    palmitate?:Micro,
}

class tubeFeed implements TubeFeedObject{
	constructor(kcal:number,protein:number,water:number,name:string,reference:string,micros:Micros){
		this._kcal=kcal;
		this._protein=protein;
		this._water=water;
		this._name=name;
		this._reference=reference;
		this._micros=micros;
	}
	_kcal = 0;
	_protein = 0;
	_water = 0;
	_name = "";
	_reference = "";
	_micros = {
        
	};

	get kcal(){
		return this._kcal;
	}
	get protein(){
		return this._protein;
	}
	get water(){
		return this._water;
	}
	get name(){
		return this._name;
	}
	get reference(){
		return this._reference;
	}
	get micros(){
		return this._micros;
	}
    
}

//Abbot
const Glucerna10 = new tubeFeed(1000,41.8,853, "Glucerna 1.0", "https://abbottnutrition.com/glucerna-1_0-cal",{
	fat:{
		name:"Fat",
		amount:12.9*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:22.8*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:3.4*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:34*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:25*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:200*(1000/237),
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:450*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:1.7*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:5.1*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:14*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:50*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:167*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.38*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.43*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.5*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.5*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:6.8*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:83.7*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:75*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:2.5*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:220*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:314*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:325*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:170*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:170*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:67*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:25*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:0.84*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.34*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.8*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.0*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:12*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:20*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:25*(1000/237),
		unit:"mcg"
	}
});
const Glucerna12 = new tubeFeed(1200,60,805, "Glucerna 1.2", "https://abbottnutrition.com/glucerna-1_2-cal",{
	fat:{
		name:"Fat",
		amount:90*(1000/1500),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:171.8*(1000/1500),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:24.1*(1000/1500),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:265*(1000/1500),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:200*(1000/1500),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:1270*(1000/1500),
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:3480*(1000/1500),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:12.8*(1000/1500),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:33*(1000/1500),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:150*(1000/1500),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:390*(1000/1500),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:800*(1000/1500),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:1.8*(1000/1500),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:2.1*(1000/1500),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:2.4*(1000/1500),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:7.2*(1000/1500),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:38*(1000/1500),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:557*(1000/1500),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:360*(1000/1500),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:12*(1000/1500),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:1670*(1000/1500),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:3030*(1000/1500),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:1920*(1000/1500),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:1200*(1000/1500),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:1200*(1000/1500),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:480*(1000/1500),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:180*(1000/1500),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:2.4*(1000/1500),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:2.4*(1000/1500),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:18*(1000/1500),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:22*(1000/1500),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:84*(1000/1500),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:240*(1000/1500),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:115*(1000/1500),
		unit:"mcg"
	}
});
const Glucerna15 = new tubeFeed(1500,82.5,759, "Glucerna 1.5", "https://abbottnutrition.com/glucerna-1_5-cal",{
	fat:{
		name:"Fat",
		amount:75,
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:133.1,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:16.1,
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:215,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:165,
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:845,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:2598,
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:10.8,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:27.6,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:125,
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:325,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:667,
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:1.5,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:1.7,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:2,
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:6,
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:29,
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:464,
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:300,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:10,
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:1380,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:2520,
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:1600,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:1000,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:1000,
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:400,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:150,
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:2,
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:2,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:15,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:18,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:70,
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:200,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:95,
		unit:"mcg"
	}
});
const Jevity10 = new tubeFeed(1060,44.3,835, "Jevity 1.0", "https://abbottnutrition.com/jevity-1_0-cal",{
	fat:{
		name:"Fat",
		amount:8.2*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:36.7*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:3.4*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:27*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:27*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:142*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.2*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:7.3*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:25*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:55*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:63*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.26*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.43*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.33*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.9*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:3.3*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:108*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:4.7*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:0.95*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:220*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:372*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:310*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:216*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:198*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:69*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:28*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:0.69*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.36*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:2.2*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:2.8*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:11*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:11*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:19*(1000/237),
		unit:"mcg"
	}
});
const Jevity12 = new tubeFeed(1200,55.5,807, "Jevity 1.2", "https://abbottnutrition.com/jevity-1_2-cal",{
	fat:{
		name:"Fat",
		amount:9.3*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:40.2*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:4*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:36*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:36*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:171*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.8*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:9.2*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:29*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:59*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:76*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.41*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.59*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.33*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2.3*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:4*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:142*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:5.7*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:0.95*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:253*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:566*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:356*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:284*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:284*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:88*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:36*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:0.81*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.41*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:2.5*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.4*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:14*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:14*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:23*(1000/237),
		unit:"mcg"
	}
});
const Jevity15 = new tubeFeed(1500,63.8,760, "Jevity 1.5", "https://abbottnutrition.com/jevity-1_5-cal",{
	fat:{
		name:"Fat",
		amount:11.8*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:51.1*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:5*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:36*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:36*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:213*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:4.7*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:13*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:40*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:71*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:95*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.45*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.76*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.54*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2.8*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:4.7*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:142*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:7.1*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:1.9*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:316*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:517*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:322*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:308*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:296*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:100*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:36*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:1.0*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.52*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.1*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:4.3*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:18*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:18*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:30*(1000/237),
		unit:"mcg"
	}
});
const Nepro = new tubeFeed(1770,81,727, "Nepro", "https://abbottnutrition.com/nepro-with-carbsteady",{
	fat:{
		name:"Fat",
		amount:23*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:38*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:6*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:63*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:38*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:180*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.8*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:6.8*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:19*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:23*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:300*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.60*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.50*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:2.21*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.2*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:8.0*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:140*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:15*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:2.25*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:250*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:225*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:200*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:250*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:170*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:40*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:38*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:0.60*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.43*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.0*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.3*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:14*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:7.5*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:11*(1000/237),
		unit:"mcg"
	}
});
const Osmolite10 = new tubeFeed(1060,44.3,835, "Osmolite 1.0", "https://abbottnutrition.com/osmolite-1_0-cal",{
	fat:{
		name:"Fat",
		amount:8.2*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:33.9*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:null,
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:27*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:27*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:142*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.2*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:7.3*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:25*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:55*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:63*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.27*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.43*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.33*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.9*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:3.3*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:108*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:4.7*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:0.95*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:220*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:372*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:341*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:206*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:198*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:69*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:28*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:0.69*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.36*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:2.2*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:2.8*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:11*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:11*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:19*(1000/237),
		unit:"mcg"
	}
});
const Osmolite12 = new tubeFeed(1200,55.5,820, "Osmolite 1.2", "https://abbottnutrition.com/osmolite-1_2-cal",{
	fat:{
		name:"Fat",
		amount:9.3*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:37.5*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:null,
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:36*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:36*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:171*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.8*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:10*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:29*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:59*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:76*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.41*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.59*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.33*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2.3*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:4*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:142*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:5.7*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:0.95*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:253*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:539*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:356*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:284*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:284*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:88*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:36*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:0.81*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.41*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:2.5*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.4*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:14*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:14*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:23*(1000/237),
		unit:"mcg"
	}
});
const Osmolite15 = new tubeFeed(1500,62.7,762, "Osmolite 1.5", "https://abbottnutrition.com/osmolite-1_5-cal",{
	fat:{
		name:"Fat",
		amount:11.6*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:48.2*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:null,
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:36*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:36*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:213*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:4.7*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:14*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:40*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:47*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:95*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.45*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.76*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.54*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2.8*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:4.7*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:142*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:7.1*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:1.9*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:316*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:517*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:403*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:308*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:296*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:100*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:36*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:1.0*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.52*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.1*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:4.3*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:18*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:19*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:30*(1000/237),
		unit:"mcg"
	}
});
const Pivot15 = new tubeFeed(1500,93.8,750, "Pivot 1.5", "https://abbottnutrition.com/pivot-1_5-cal",{
	fat:{
		name:"Fat",
		amount:12*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:40.9*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:1.8*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:36*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:36*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:427*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:6.6*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:6.4*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:30*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:72*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:145*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.47*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.50*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.55*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.2*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:6.0*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:180*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:7.1*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:2.1*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:350*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:470*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:380*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:240*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:230*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:100*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:40*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:1.2*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.52*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:7.3*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:5.6*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:19*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:25*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:40*(1000/237),
		unit:"mcg"
	}
});
const Promote = new tubeFeed(1000,63,839, "Promote","https://abbottnutrition.com/promote",{
	fat:{
		name:"Fat",
		amount:26,
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:130,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:null,
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:150,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:150,
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:720,
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:17,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:25,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:90,
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:150,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:267,
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:1,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:1.3,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:1.4,
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.6,
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:14,
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:367,
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:20,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3,
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:933,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:2667,
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:1533,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:867,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:833,
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:280,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:100,
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:2.9,
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:1.5,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:9,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:12,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:48,
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:48,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:82,
		unit:"mcg"
	}
});
const PromoteWithFiber = new tubeFeed(1000,63,831, "Promite With Fiber", "https://abbottnutrition.com/promote-with-fiber",{
	fat:{
		name:"Fat",
		amount:28,
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:139,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:14.4,
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:150,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:150,
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:720,
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:16.7,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:25.3,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:90,
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:150,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:267,
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:1,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:1.3,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:1.4,
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.6,
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:14,
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:367,
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:20,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3,
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:991,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:2200,
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:1310,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:867,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:933,
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:280,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:119,
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:2.9,
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:1.7,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:9,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:12,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:48,
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:48,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:82,
		unit:"mcg"
	}
});
const Pulmocare = new tubeFeed(1500,62.6,785, "Pulmocare", "https://abbottnutrition.com/pulmocare",{
	fat:{
		name:"Fat",
		amount:93.3,
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:105.7,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:null,
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:160,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:160,
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:3570,
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:10.6,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:66.5,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:85,
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:320,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:1408,
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:3.2,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:3.6,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:4.3,
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:13,
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:47,
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:527,
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:635,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:22,
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:1310,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:1780,
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:1690,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:1060,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:1060,
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:425,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:160,
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:5.3,
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:2.2,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:24,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:19,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:74,
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:130,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:160,
		unit:"mcg"
	}
});
const Suplena = new tubeFeed(1800,44.7,725.7, "Suplena", "https://abbottnutrition.com/suplena-with-carbsteady",{
	fat:{
		name:"Fat",
		amount:23*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:46*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:6*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:63*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:38*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:225*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:5*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:6.8*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:19*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:23*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:300*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.6*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.5*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:2.21*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.2*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:8*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:140*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:15*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:2.25*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:190*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:250*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:210*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:250*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:170*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:40*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:38*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:0.6*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.42*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.3*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:13.8*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:7.5*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:11*(1000/237),
		unit:"mcg"
	}
});
const TwoCalHN = new tubeFeed(2000,83.5,700, "TwoCal HN", "https://abbottnutrition.com/twocal-hn",{
	fat:{
		name:"Fat",
		amount:21.5*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:51.8*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:1.2*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:50*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:50*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:375*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:5.8*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:8*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:40*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:75*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:272*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.6*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.65*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.85*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2.4*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:8*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:200*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:20*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:4*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:200*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:500*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:230*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:325*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:313*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:98*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:50*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:1.3*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.5*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:5.7*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:6*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:18*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:12*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:15*(1000/237),
		unit:"mcg"
	}
});
const Vital10 = new tubeFeed(1000,40,834, "Vital 1.0", "https://abbottnutrition.com/vital-1_0-cal",{
	fat:{
		name:"Fat",
		amount:9*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:30.7*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:1*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:25*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:25*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:330*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:4.3*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:4.3*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:20*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:71*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:150*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.54*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.59*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.88*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.9*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:4.2*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:120*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:9*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:2*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:204*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:350*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:250*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:205*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:198*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:66*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:25*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:0.68*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.33*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:4*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:11*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:13*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:24*(1000/237),
		unit:"mcg"
	}
});
const VitalAF12 = new tubeFeed(1200,75,811, "Vital AF 1.2", "https://abbottnutrition.com/vital-af-1_2-cal",{
	fat:{
		name:"Fat",
		amount:53.9,
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:110.6,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:5.1,
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:125,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:125,
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:1540,
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:25.8,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:20.3,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:141,
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:181,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:523,
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:2.5,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:1.7,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:2.3,
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:7,
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:33.8,
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:443,
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:30.4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:7.3,
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:1266,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:1645,
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:1265,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:1046,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:1004,
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:337,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:127,
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:3.4,
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:1.5,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:20.3,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:15.2,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:63,
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:68,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:108,
		unit:"mcg"
	}
});
const Vital15 = new tubeFeed(1500,67.5,764, "Vital 1.5", "https://abbottnutrition.com/vital-1_5-cal",{
	fat:{
		name:"Fat",
		amount:13.5*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:44.2*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:1.4*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:35*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:35*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:433*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:5*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:5.2*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:35*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:40*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:154*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.7*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.5*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.9*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:7.9*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:184*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:8.9*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:2.1*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:270*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:520*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:355*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:308*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:297*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:100*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:36*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:1.2*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.52*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:7.2*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:4.4*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:19*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:24*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:43*(1000/237),
		unit:"mcg"
	}
});
const VitalHighProtein = new tubeFeed(1000,87.3,836, "Vital High Protein", "https://abbottnutrition.com/vital-hp",{
	fat:{
		name:"Fat",
		amount:5.5*(1000/237),
		unit:"g"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:26.7*(1000/237),
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:0*(1000/237),
		unit:"g"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:25*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:25*(1000/237),
		unit:"mg"
	},
	inositol:{
		name:"Inositol",
		amount:null,
		unit:"mg"
	},
	vitA:{
		name:"Vitamin A",
		amount:166*(1000/237),
		unit:"mcg RAE"
	},
	vitD:{
		name:"Vitamin D",
		amount:5*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:4*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:39*(1000/237),
		unit:"mg"
	},
	vitC:{
		name:"Vitamin C",
		amount:82*(1000/237),
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:100*(1000/237),
		unit:"mcg DFE"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.5*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.3*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.5*(1000/237),
		unit:"mg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2*(1000/237),
		unit:"mcg"
	},
	niacin:{
		name:"Niacin",
		amount:6.8*(1000/237),
		unit:"mg NE"
	},
	choline:{
		name:"Choline",
		amount:118*(1000/237),
		unit:"mg"
	},
	biotin:{
		name:"Biotin",
		amount:8*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:1.4*(1000/237),
		unit:""
	},
	sodium:{
		name:"Sodium",
		amount:330*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:332*(1000/237),
		unit:"mg"
	},
	chloride:{
		name:"Chloride",
		amount:245*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:206*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:198*(1000/237),
		unit:"mg"
	},
	magnesium:{
		name:"Magnesium",
		amount:67*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:25*(1000/237),
		unit:"mcg"
	},
	manganese:{
		name:"Manganese",
		amount:0.8*(1000/237),
		unit:"mg"
	},
	copper:{
		name:"Copper",
		amount:0.4*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:4.7*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:15*(1000/237),
		unit:"mcg"
	},
	chromium:{
		name:"Chromium",
		amount:20*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:32*(1000/237),
		unit:"mcg"
	}
});
//Nestle
const Compleat = new tubeFeed(1060,48,828, "Compleat", "https://www.nestlemedicalhub.com/products/compleat",{
	fat:{
		name:"Fat",
		amount:10*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:null,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:250*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:390*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:34*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:150*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:250*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:60*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:220*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.5*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.5*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:5.5*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:25*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.5*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.5*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:3.5*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.7*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:50*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:10*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:210*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:30*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:75*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:5*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:15*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.4*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.8*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:35*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:30*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:220*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:110*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:30*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:30*4,
		unit:"mg"
	}
});
const DiabetisourceAC = new tubeFeed (1200,60,816, "Diabetisource AC", "https://www.nestlemedicalhub.com/products/diabetisource-ac",{
	fat:{
		name:"Fat",
		amount:14.7*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:null,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:265*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:400*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:25*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:200*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:275*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:60*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:200*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.6*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:4*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:8*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:24*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.5*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.6*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:4*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.8*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:100*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.8*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:60*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3.2*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:200*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:30*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:80*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:4.8*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:16*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.4*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.92*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:40*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:30*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:160*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:120*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:30*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:30*4,
		unit:"mg"
	}
});
const FibersourceHN = new tubeFeed(1200,54,808, "Fibersource HN", "https://www.nestlemedicalhub.com/products/fibersource-hn",{
	fat:{
		name:"Fat",
		amount:10*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:2*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:280*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:480*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:41*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:160*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:320*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:60*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:240*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:4*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:3*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:6*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:24*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.6*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.6*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:4*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.8*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:100*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.5*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:12*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3.5*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:240*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:30*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:85*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:4*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:16*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.4*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.9*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:24*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:30*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:325*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:120*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:30*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:30*4,
		unit:"mg"
	}
});
const Glytrol = new tubeFeed(1000,45.2,840, "Glytrol","https://www.nestlemedicalhub.com/products/glytrol",{
	fat:{
		name:"Fat",
		amount:11.9*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:2.4*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:185*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:350*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:25*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:180*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:400*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:35*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:180*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.2*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:1.7*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:5*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:12.5*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.5*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.6*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:7*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:1*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:100*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:75*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3.5*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:180*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:30*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:71.5*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.8*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:19*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.38*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.75*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:31*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:50*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:300*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:100*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:25*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:25*4,
		unit:"mg"
	}
});
const Impact = new tubeFeed(1000,56,852, "Impact", "https://www.nestlemedicalhub.com/products/impact",{
	fat:{
		name:"Fat",
		amount:7*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:null,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:240*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:400*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:33*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:250*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:500*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:20*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:200*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.3*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:10*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:17*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.5*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.43*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:5*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.4*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:100*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:50*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:1.7*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:200*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:25*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:68*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.8*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:25*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.42*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.5*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:25*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:50*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:325*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:68*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:null,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:null,
		unit:"mg"
	}
});
const ImpactPeptide15 = new tubeFeed(1500,94,772, "Impact Peptide 1.5", "https://www.nestlemedicalhub.com/products/impact-peptide-15",{
	fat:{
		name:"Fat",
		amount:15.9*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:7.9*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:292*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:468*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:35*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:560*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:1125*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:250*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:250*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:4.5*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:5*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:17*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:18.7*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.75*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.6*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:7*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:1*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:135*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:100*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3.5*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:250*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:40*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:105*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:9*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:25*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.75*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:1*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:35*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:55*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:435*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:138*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:37.5*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:70*4,
		unit:"mg"
	}
});
const ImpactAdvancedRecovery = new tubeFeed(1120,104,812, "Impact Advanced Recovery", "https://www.nestlemedicalhub.com/products/impact-advanced-recovery",{
	fat:{
		name:"Fat",
		amount:11*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:1.8*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:270*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:600*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:20*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:440*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:870*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:75*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:380*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:6*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:5.7*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:14*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:33*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.6*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.9*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:8*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.75*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:95*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.2*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:15*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:4.1*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:350*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:75*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:105*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:7.5*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:24*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.45*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:1*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:18*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:22*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:600*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:120*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:null,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:null,
		unit:"mg"
	}
});
const IsosourceHN = new tubeFeed(1200,54,808, "Isosource HN", "https://www.nestlemedicalhub.com/products/isosource-hn",{
	fat:{
		name:"Fat",
		amount:10*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:2*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:280*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:480*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:39*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:160*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:320*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:60*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:240*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:4*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.5*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:6*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:24*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.8*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.6*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:4*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.8*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:100*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.5*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:10*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3.5*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:240*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:30*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:85*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:4*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:16*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.4*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.9*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:25*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:30*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:350*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:120*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:30*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:30*4,
		unit:"mg"
	}
});
const Isosource15 = new tubeFeed(1500,68,764, "Isosource 1.5", "https://www.nestlemedicalhub.com/products/isosource-15-cal",{
	fat:{
		name:"Fat",
		amount:14.8*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:3*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:325*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:600*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:44*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:195*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:385*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:75*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:300*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:4.5*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:4*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:7.5*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:31*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.9*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.8*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:5.4*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:1.2*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:138*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.8*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:17*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:4.7*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:300*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:38*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:105*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:5*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:20*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.5*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:1.2*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:30*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:38*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:440*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:150*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:42*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:42*4,
		unit:"mg"
	}
});
const Nutren20 = new tubeFeed(2000,84,692, "Nutren 2.0", "https://www.nestlemedicalhub.com/products/nutren-20",{
	fat:{
		name:"Fat",
		amount:23*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:11.5*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:375*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:525*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:54*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:260*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:520*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:100*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:400*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:6*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:5*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:10*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:40*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:1*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:1*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:6*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:1.3*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:160*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2.5*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:20*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:5*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:370*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:50*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:140*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:6*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:25*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.6*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:1.5*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:40*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:50*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:375*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:200*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:50*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:50*4,
		unit:"mg"
	}
});
const NovasourceRenal = new tubeFeed (2005,93,709, "Novasource Renal", "https://www.nestlemedicalhub.com/products/novasource-renal",{
	fat:{
		name:"Fat",
		amount:24*(1000/237),
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:null,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:220*(1000/237),
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:230*(1000/237),
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:43*(1000/237),
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:110*(1000/237),
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:250*(1000/237),
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:22*(1000/237),
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:200*(1000/237),
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:4.3*(1000/237),
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:2.5*(1000/237),
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:7*(1000/237),
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:30*(1000/237),
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.4*(1000/237),
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.9*(1000/237),
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:4.8*(1000/237),
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:2*(1000/237),
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:240*(1000/237),
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.5*(1000/237),
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:12*(1000/237),
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:2.5*(1000/237),
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:200*(1000/237),
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:45*(1000/237),
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:50*(1000/237),
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:5.2*(1000/237),
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:26*(1000/237),
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.5*(1000/237),
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:1*(1000/237),
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:25*(1000/237),
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:21*(1000/237),
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:190*(1000/237),
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:130*(1000/237),
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:63*(1000/237),
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:36*(1000/237),
		unit:"mg"
	}
});
const Nutren10 = new tubeFeed(1000,40,844, "Nutren 1.0", "https://www.nestlemedicalhub.com/products/nutren-10",{
	fat:{
		name:"Fat",
		amount:8.5*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:1.7*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:220*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:400*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:34*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:130*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:260*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:50*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:200*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.5*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:2.5*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:5*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:20*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.5*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.5*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:3*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.7*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:85*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.2*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:10*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:200*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:25*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:70*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.5*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:15*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.4*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.8*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:20*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:25*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:300*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:100*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:25*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:25*4,
		unit:"mg"
	}
});
const Nutren10WithFiber = new tubeFeed(1000,40,832, "Nutren 1.0 With Fiber", "https://www.nestlemedicalhub.com/products/nutren-10-fiber",{
	fat:{
		name:"Fat",
		amount:8.5*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:1.7*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:220*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:400*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:37*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:130*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:260*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:50*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:200*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.5*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:2.5*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:5*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:20*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.5*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.5*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:3*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.7*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:85*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.2*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:10*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:200*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:25*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:70*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.5*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:15*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.4*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.8*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:20*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:25*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:325*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:100*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:25*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:25*4,
		unit:"mg"
	}
});
const Nutren15 = new tubeFeed(1500,68,764, "Nutren 1.5", "https://www.nestlemedicalhub.com/products/nutren-15",{
	fat:{
		name:"Fat",
		amount:15*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:3*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:325*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:600*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:44*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:200*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:390*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:75*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:300*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:5*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:4*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:8*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:30*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.9*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.8*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:5*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:1*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:120*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.8*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:15*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:4*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:300*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:40*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:105*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:5*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:20*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.5*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:1.2*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:30*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:40*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:400*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:150*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:40*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:40*4,
		unit:"mg"
	}
});
const Peptamen = new tubeFeed(1000,40,848, "Peptamen", "https://www.nestlemedicalhub.com/products/peptamen",{
	fat:{
		name:"Fat",
		amount:10*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:7*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:120*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:400*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:32*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:200*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:250*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:30*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:200*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:4*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.3*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:5*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:20*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.6*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.5*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:5.5*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.6*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:50*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:8*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:1.2*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:175*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:35*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:75*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.5*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:18*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.5*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.7*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:12*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:25*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:250*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:120*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:25*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:25*4,
		unit:"mg"
	}
});
const Peptamen15 = new tubeFeed(1500,68,768, "Peptamen 1.5", "https://www.nestlemedicalhub.com/products/peptamen-15",{
	fat:{
		name:"Fat",
		amount:14*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:10*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:220*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:520*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:47*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:300*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:375*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:45*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:250*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:6*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:5*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:7.5*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:30*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.8*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.8*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:8.3*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.9*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:75*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:3*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:12*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:1.8*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:250*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:50*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:105*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:5.5*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:25*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.8*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:1.1*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:18*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:40*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:440*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:180*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:40*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:40*4,
		unit:"mg"
	}
});
const PeptamenAF12 = new tubeFeed(1200,76,812, "Peptamen AF 1.2", "https://www.nestlemedicalhub.com/products/peptamen-af",{
	fat:{
		name:"Fat",
		amount:13.5*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:7*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:180*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:450*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:28*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:250*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:300*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:35*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:200*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:5*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:4*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:8*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:25*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.7*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.6*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:6.6*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.7*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:60*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:3*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:10*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:1.5*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:200*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:45*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:85*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:4.4*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:20*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.6*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.8*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:15*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:45*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:340*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:140*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:30*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:30*4,
		unit:"mg"
	}
});
const PeptamenIntenseVHP = new tubeFeed(1000,92,840, "Peptamen Intense VHP", "https://www.nestlemedicalhub.com/products/peptamen-intense-vhp",{
	fat:{
		name:"Fat",
		amount:9.5*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:4.5*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:170*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:375*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:19*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:200*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:250*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:30*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:170*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:4*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.3*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:5*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:20*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.6*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.5*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:5.5*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.6*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:50*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:2*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:8*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:1.2*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:170*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:35*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:75*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.5*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:20*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.5*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.7*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:15*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:40*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:280*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:120*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:25*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:25*4,
		unit:"mg"
	}
});
const Renalcal = new tubeFeed(2000,34,704, "Renalcal", "https://www.nestlemedicalhub.com/products/renalcal",{
	fat:{
		name:"Fat",
		amount:20.5*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:15*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:15*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:20*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:73*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:null,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:null,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:15*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:15*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:null,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:null,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:null,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:null,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.4*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.4*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:5*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:1.8*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:150*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.5*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:75*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:2.5*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:25*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:null,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:5*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3.5*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:12.5*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:null,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:null,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:null,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:null,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:null,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:100*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:25*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:25*4,
		unit:"mg"
	}
});
const Replete = new tubeFeed(1000,64,840, "Replete", "https://www.nestlemedicalhub.com/products/replete",{
	fat:{
		name:"Fat",
		amount:8.5*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:1.7*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:220*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:400*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:28*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:130*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:260*4,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:50*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:200*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3.5*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:2.5*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:5*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:20*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.5*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.5*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:3*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.7*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:85*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.2*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:10*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:3*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:200*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:25*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:70*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:4*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:17*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.4*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.9*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:25*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:25*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:225*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:100*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:25*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:25*4,
		unit:"mg"
	}
});
const VivonexRTF = new tubeFeed(1000,50,848, "Vivonex RTF", "https://www.nestlemedicalhub.com/products/vivonex-rtf",{
	fat:{
		name:"Fat",
		amount:2.9*4,
		unit:"g"
	},
	mct:{
		name:"MCT",
		amount:1.2*4,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:175*4,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:300*4,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:44*4,
		unit:"g"
	},
	retinol:{
		name:"Retinol",
		amount:250*4,
		unit:"mcg"
	},
	betaCarotene:{
		name:"B-Carotene",
		amount:null,
		unit:"mcg"
	},
	vitC:{
		name:"Vitamin C",
		amount:17*4,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:167*4,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:3*4,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:3.5*4,
		unit:"mcg"
	},
	vitE:{
		name:"Vitamin E",
		amount:3.5*4,
		unit:"mg"
	},
	vitK:{
		name:"Vitamin K",
		amount:13.3*4,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:0.42*4,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:0.47*4,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:5.5*4,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:0.55*4,
		unit:"mg"
	},
	folicAcid:{
		name:"Folic Acid",
		amount:110*4,
		unit:"mcg"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:1.7*4,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:83*4,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:2.8*4,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:167*4,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:26.5*4,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:67*4,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:3*4,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:11.5*4,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:0.33*4,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:0.33*4,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:20*4,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:12.5*4,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:200*4,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:55*4,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:16.7*4,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:16.7*4,
		unit:"mg"
	}
});
//Kate farms
const KateFarmsStandard10 = new tubeFeed(1000,49.2,800, "Kate Farms Standard 1.0", "https://shop.katefarms.com/products/standard-1-0-plain",{
	fat:{
		name:"Fat",
		amount:37,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:800,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:1692,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:117,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:15,
		unit:"g"
	},
	palmitate:{
		name:"Vitamin A-Palmitate",
		amount:1477,
		unit:"mcg RAE"
	},
	betaCarotene:{
		name:"Vitamin A-Beta Carotene",
		amount:815,
		unit:"mcg RAE"
	},
	vitC:{
		name:"Vitamin C",
		amount:154,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:1077,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:15,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:862,
		unit:"IU"
	},
	vitE:{
		name:"Vitamin E",
		amount:34,
		unit:"IU"
	},
	vitK:{
		name:"Vitamin K",
		amount:92,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:2.2,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:1.8,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:22,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:2.2,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:738,
		unit:"mcg DFE"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:6.5,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:62,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:12,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:923,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:123,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:354,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:20,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:75,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:2.2,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:2.5,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:49,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:108,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:1031,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:477,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:123,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:123,
		unit:"mg"
	}
});
const KateFarmsStandard14 = new tubeFeed(1400,61.5,720, "Kate Farms Standard 1.4", "https://shop.katefarms.com/products/standard-1-4-plain-formula",{
	fat:{
		name:"Fat",
		amount:58,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:1077,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:2231,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:157,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:9,
		unit:"g"
	},
	palmitate:{
		name:"Vitamin A-Palmitate",
		amount:1477,
		unit:"mcg RAE"
	},
	betaCarotene:{
		name:"Vitamin A-Beta Carotene",
		amount:1612,
		unit:"mcg RAE"
	},
	vitC:{
		name:"Vitamin C",
		amount:154,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:1077,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:22,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:862,
		unit:"IU"
	},
	vitE:{
		name:"Vitamin E",
		amount:62,
		unit:"IU"
	},
	vitK:{
		name:"Vitamin K",
		amount:123,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:1.5,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:1.8,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:22,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:2.2,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:923,
		unit:"mcg DFE"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:6,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:62,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:11,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:923,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:200,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:431,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:18,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:75,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:2.2,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:2.5,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:92,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:154,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:1615,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:554,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:123,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:123,
		unit:"mg"
	}
});
const KateFarmsPeptide10 = new tubeFeed(1000,49.2,790, "Kate Farms Peptide 1.0", "https://shop.katefarms.com/products/peptide-1-0-plain-formula",{
	fat:{
		name:"Fat",
		amount:40,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:800,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:1700,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:115,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:15,
		unit:"g"
	},
	palmitate:{
		name:"Vitamin A-Palmitate",
		amount:1480,
		unit:"mcg RAE"
	},
	betaCarotene:{
		name:"Vitamin A-Beta Carotene",
		amount:810,
		unit:"mcg RAE"
	},
	vitC:{
		name:"Vitamin C",
		amount:155,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:1080,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:15,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:860,
		unit:"IU"
	},
	vitE:{
		name:"Vitamin E",
		amount:32,
		unit:"IU"
	},
	vitK:{
		name:"Vitamin K",
		amount:92,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:2.2,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:1.8,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:22,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:2.2,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:740,
		unit:"mcg DFE"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:6.5,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:62,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:12,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:925,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:125,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:350,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:20,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:75,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:2.2,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:2.5,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:49,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:110,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:1050,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:475,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:125,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:125,
		unit:"mg"
	}
});
const KateFarmsPeptide15 = new tubeFeed(1538,73.8,700, "Kate Farms Peptide 1.5", "https://shop.katefarms.com/products/peptide-plain-1-5-formula",{
	fat:{
		name:"Fat",
		amount:77,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:1077,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:2462,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:138,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:9,
		unit:"g"
	},
	palmitate:{
		name:"Vitamin A-Palmitate",
		amount:1477,
		unit:"mcg RAE"
	},
	betaCarotene:{
		name:"Vitamin A-Beta Carotene",
		amount:809,
		unit:"mcg RAE"
	},
	vitC:{
		name:"Vitamin C",
		amount:154,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:1077,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:22,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:862,
		unit:"IU"
	},
	vitE:{
		name:"Vitamin E",
		amount:62,
		unit:"IU"
	},
	vitK:{
		name:"Vitamin K",
		amount:123,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:1.5,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:1.8,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:22,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:2.2,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:923,
		unit:"mcg DFE"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:6,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:62,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:11,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:923,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:200,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:431,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:18,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:77,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:2.2,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:2.5,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:92,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:154,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:1615,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:554,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:123,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:123,
		unit:"mg"
	}
});
const KateFarmsGlucoseSupport12 = new tubeFeed(1200,64,760, "Kate Farms Glucose Support 1.2", "https://shop.katefarms.com/products/glucose-support-1-2?variant=41796131979440",{
	fat:{
		name:"Fat",
		amount:60,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:1000,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:1600,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:104,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:20,
		unit:"g"
	},
	palmitate:{
		name:"Vitamin A-Palmitate",
		amount:2200,
		unit:"mcg RAE"
	},
	betaCarotene:{
		name:"Vitamin A-Beta Carotene",
		amount:1200,
		unit:"mcg RAE"
	},
	vitC:{
		name:"Vitamin C",
		amount:300,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:880,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:16,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:1200,
		unit:"IU"
	},
	vitE:{
		name:"Vitamin E",
		amount:88,
		unit:"IU"
	},
	vitK:{
		name:"Vitamin K",
		amount:100,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:1.6,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:2,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:16,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:3.2,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:400,
		unit:"mcg DFE"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:4.8,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:92,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:16,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:720,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:160,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:420,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:16,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:80,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:2,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:2,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:200,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:80,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:1280,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:520,
		unit:"mg"
	}
});
const KateFarmsRenalSupport18 = new tubeFeed(1800,80,670, "Kate Farms Renal Support 1.8", "https://shop.katefarms.com/products/renal-support-1-8?variant=41798138331312",{
	fat:{
		name:"Fat",
		amount:88,
		unit:"g"
	},
	sodium:{
		name:"Sodium",
		amount:1000,
		unit:"mg"
	},
	potassium:{
		name:"Potassium",
		amount:1000,
		unit:"mg"
	},
	carbohydrate:{
		name:"Carbohydrate",
		amount:172,
		unit:"g"
	},
	fiber:{
		name:"Fiber",
		amount:16,
		unit:"g"
	},
	palmitate:{
		name:"Vitamin A-Palmitate",
		amount:360,
		unit:"mcg RAE"
	},
	betaCarotene:{
		name:"Vitamin A-Beta Carotene",
		amount:800,
		unit:"mcg RAE"
	},
	vitC:{
		name:"Vitamin C",
		amount:100,
		unit:"mg"
	},
	calcium:{
		name:"Calcium",
		amount:1200,
		unit:"mg"
	},
	iron:{
		name:"Iron",
		amount:24,
		unit:"mg"
	},
	vitD:{
		name:"Vitamin D",
		amount:1200,
		unit:"IU"
	},
	vitE:{
		name:"Vitamin E",
		amount:32,
		unit:"IU"
	},
	vitK:{
		name:"Vitamin K",
		amount:100,
		unit:"mcg"
	},
	thiamin:{
		name:"Thiamin",
		amount:2,
		unit:"mg"
	},
	riboflavin:{
		name:"Riboflavin",
		amount:2.8,
		unit:"mg"
	},
	niacin:{
		name:"Niacin",
		amount:20,
		unit:"mg"
	},
	vitB6:{
		name:"Vitamin B6",
		amount:8.8,
		unit:"mg"
	},
	folate:{
		name:"Folate",
		amount:1700,
		unit:"mcg DFE"
	},
	vitB12:{
		name:"Vitamin B12",
		amount:7.2,
		unit:"mcg"
	},
	biotin:{
		name:"Biotin",
		amount:60,
		unit:"mcg"
	},
	pantothenicAcid:{
		name:"Pantothenic Acid",
		amount:15,
		unit:"mg"
	},
	phosphorus:{
		name:"Phosphorus",
		amount:760,
		unit:"mg"
	},
	iodine:{
		name:"Iodine",
		amount:160,
		unit:"mcg"
	},
	magnesium:{
		name:"Magnesium",
		amount:180,
		unit:"mg"
	},
	zinc:{
		name:"Zinc",
		amount:23,
		unit:"mg"
	},
	selenium:{
		name:"Selenium",
		amount:80,
		unit:"mcg"
	},
	copper:{
		name:"Copper",
		amount:2,
		unit:"mg"
	},
	manganese:{
		name:"Manganese",
		amount:2,
		unit:"mg"
	},
	chromium:{
		name:"Chromium",
		amount:120,
		unit:"mcg"
	},
	molybdenum:{
		name:"Molybdenum",
		amount:80,
		unit:"mcg"
	},
	chloride:{
		name:"Chloride",
		amount:880,
		unit:"mg"
	},
	choline:{
		name:"Choline",
		amount:640,
		unit:"mg"
	},
	lCarnitine:{
		name:"L-Carnitine",
		amount:260,
		unit:"mg"
	},
	taurine:{
		name:"Taurine",
		amount:152,
		unit:"mg"
	}
});

export type FormulasType = "Compleat" | "DiabetisourceAC" | "FibersourceHN" | "Glucerna10" | "Glucerna12" | "Glucerna15" | "Glytrol" | "Impact" | "ImpactAdvancedRecovery" | "ImpactPeptide15" | "Isosource15" | "IsosourceHN" | "Jevity10" | "Jevity12" | "Jevity15" | "KateFarmsGlucoseSupport12" | "KateFarmsRenalSupport18" | "KateFarmsStandard10" | "KateFarmsStandard14" | "KateFarmsPeptide10" | "KateFarmsPeptide15" | "Nepro" | "NovasourceRenal" | "Nutren10" | "Nutren10WithFiber" | "Nutren15" | "Nutren20" | "Osmolite10" | "Osmolite12" | "Osmolite15" | "Peptamen" | "PeptamenAF12" | "Peptamen15" | "PeptamenIntenseVHP" | "Pivot15" | "Promote" | "PromoteWithFiber" | "Pulmocare" | "Renalcal" | "Replete" | "Suplena" | "TwoCalHN" | "Vital10" | "VitalAF12" | "Vital15" | "VitalHighProtein" | "VivonexRTF"

export interface FormulasContainerType {
    Compleat: TubeFeedObject;
    DiabetisourceAC: TubeFeedObject;
    FibersourceHN: TubeFeedObject;
    Glucerna10: TubeFeedObject;
    Glucerna12: TubeFeedObject;
    Glucerna15: TubeFeedObject;
    Glytrol: TubeFeedObject;
    Impact: TubeFeedObject;
    ImpactAdvancedRecovery: TubeFeedObject;
    ImpactPeptide15: TubeFeedObject;
    Isosource15: TubeFeedObject;
    IsosourceHN: TubeFeedObject;
    Jevity10: TubeFeedObject;
    Jevity12: TubeFeedObject;
    Jevity15: TubeFeedObject;
    KateFarmsGlucoseSupport12: TubeFeedObject;
    KateFarmsRenalSupport18: TubeFeedObject;
    KateFarmsStandard10: TubeFeedObject;
    KateFarmsStandard14: TubeFeedObject;
    KateFarmsPeptide10: TubeFeedObject;
    KateFarmsPeptide15: TubeFeedObject;
    Nepro: TubeFeedObject;
    NovasourceRenal: TubeFeedObject;
    Nutren10: TubeFeedObject;
    Nutren10WithFiber: TubeFeedObject;
    Nutren15: TubeFeedObject;
    Nutren20: TubeFeedObject;
    Osmolite10: TubeFeedObject;
    Osmolite12: TubeFeedObject;
    Osmolite15: TubeFeedObject;
    Peptamen: TubeFeedObject;
    PeptamenAF12: TubeFeedObject;
    Peptamen15: TubeFeedObject;
    PeptamenIntenseVHP: TubeFeedObject;
    Pivot15: TubeFeedObject;
    Promote: TubeFeedObject;
    PromoteWithFiber: TubeFeedObject;
    Pulmocare: TubeFeedObject;
    Renalcal: TubeFeedObject;
    Replete: TubeFeedObject;
    Suplena: TubeFeedObject;
    TwoCalHN: TubeFeedObject;
    Vital10: TubeFeedObject;
    VitalAF12: TubeFeedObject;
    Vital15: TubeFeedObject;
    VitalHighProtein: TubeFeedObject;
    VivonexRTF: TubeFeedObject;
}
export const Formulas = {
	Compleat,
	DiabetisourceAC,
	FibersourceHN,
	Glucerna10,
	Glucerna12,
	Glucerna15,
	Glytrol,
	Impact,
	ImpactAdvancedRecovery,
	ImpactPeptide15,
	Isosource15,
	IsosourceHN,
	Jevity10,
	Jevity12,
	Jevity15,
	KateFarmsGlucoseSupport12,
	KateFarmsRenalSupport18,
	KateFarmsStandard10,
	KateFarmsStandard14,
	KateFarmsPeptide10,
	KateFarmsPeptide15,
	Nepro,
	NovasourceRenal,
	Nutren10,
	Nutren10WithFiber,
	Nutren15,
	Nutren20,
	Osmolite10,
	Osmolite12,
	Osmolite15,
	Peptamen,
	PeptamenAF12,
	Peptamen15,
	PeptamenIntenseVHP,
	Pivot15,
	Promote,
	PromoteWithFiber,
	Pulmocare,
	Renalcal,
	Replete,
	Suplena,
	TwoCalHN,
	Vital10,
	VitalAF12,
	Vital15,
	VitalHighProtein,
	VivonexRTF
};